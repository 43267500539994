<template>
  <div>
    <mainone :mainonebread="mainonebread"></mainone>
    <maintwo :maintwoiconshow="maintwoiconshow" :maintwoforminfo="maintwoforminfo"
      :myinputoneisshow="maintwoforminfo.myinputoneisshow" :myinputone="maintwoforminfo.myinputone"
      :myselectleftisshow="maintwoforminfo.selectleftisshow" :myselectleft="maintwoforminfo.selectleft"
      :myselecttopisshow="maintwoforminfo.selecttopisshow" :myselecttop="maintwoforminfo.selecttop"
      :myinputishow="myinputishow" :myinput="myinput" :myinputonefullisshow="maintwoforminfo.myinputonefullisshow"
      :myinputonefull="maintwoforminfo.myinputonefull" :myinputonefullgaoisshow="myinputonefullgaoisshow"
      :myinputonefullgao="myinputonefullgao" :myselectisshow="myselectisshow" :myselect="myselect"
      :myselectuptopisshow="myselecttopisshow" :myselectuptop="myselecttop"
      :myselectcity="myselectcity" v-on:getsearchinfo="getSeachinfoEvent" v-on:searchForm="searchFormEvent"></maintwo>
    <!--table表单-->
    <div>
      <!--主list列表-->
      <div style="margin-top: 10px;background-color: #ffffff;border-radius: 4px;line-height: 40px;padding: 0 20px;">
        <div style="line-height: 60px">
          <!--左侧名称-->
          <div style="float: left; font-size: 14px">数据列表</div>
          <!--右侧按钮-->
          <div style="float: right">
            <!--自定义按钮-点击出现弹框&点击跳转子类页面@click="routeraddrClick('/XX路由')"-->
            <!--1、点击出现弹框@click="addTanClick(XX弹框配置信息,dialogVisible = true);resetFormSenior(XXform表单)"-->
            <!--2、点击跳转子类页面@click="routeraddrClick('/XX路由')-->
            <!--<el-button ></el-button>-->
            <!--导出按钮-点击导出数据-->
            <el-button type="warning" plain @click="exportDatas()">导出</el-button>
          </div>
        </div>

        <div>
          <!--table表单-->
          <el-table ref="multipleTable" :row-class-name="tableRowClassName" :data="
            mainthreetableData.slice(
              (currentPage - lastpage) * PageSize,
              currentPage * PageSize
            )
          " style="width: 100%" min-height="900" border v-loading="loading" element-loading-text="正在飞速加载中..."
            @selection-change="handleSelectionChange" @cell-click="handleOneData">
            <!--多选框-用于用户选择性导出数据-->
            <el-table-column type="selection" width="55" v-if="mainthreeisshowset" />
            <el-table-column v-for="(mytabletitle, i) in mainthreetabletitle" :key="i" :fixed="mytabletitle.fixedstatu"
              :prop="mytabletitle.prop" :label="mytabletitle.label" :width="mytabletitle.width">
              <!--这里先通过table列表中的title值进行判断，在进行渲染展示-->
              <!--例如：status状态字段，判断最终的值是那种表现形式等-->
              <template #default="scope" v-if="mytabletitle.prop === 'images'">
                <div v-if="mytabletitle.prop === 'images'">
                  <div v-if="scope.row.images">
                    <el-image class="imagesshow" :src="scope.row.images" :preview-src-list="[scope.row.images]"
                      fit="cover"></el-image>
                  </div>
                </div>
              </template>
            </el-table-column>
            <!--操作&按钮-->
            <el-table-column fixed="right" label="操作" :width="mainthreehandle" v-if="mainthreeisshowope"
              prop="myoperation" id="operation">
              <template #default="scope">
				  <!-- <el-button type="success" size="mini" @click="routeraddrClick('/companyedit', scope.row)" v-if="mypowerlimits.ddlb_ck">查看详情 </el-button> -->
				  <el-button type="primary" size="mini" @click="fahuo( scope.row,scope.$index)" 
				  v-if="mypowerlimits.ddlb_fh && scope.row.pay_status==1 && scope.row.mobiletype>0 && (scope.row.mobileinfo?.deliveryNo == undefined || !scope.row.mobileinfo || !scope.row.mobileinfo.deliveryNo)">发货</el-button>
				  <el-button type="danger" size="mini" @click="cancelorder(scope.row)" v-if="mypowerlimits.ddlb_qxdd && scope.row.pay_status==1">取消订单 </el-button>
				  <el-button type="warning " size="mini" @click="refundorder( scope.row)" v-if="mypowerlimits.ddlb_tk && scope.row.pay_status== -3"
				      >退款
				  </el-button>
              </template>
            </el-table-column>
          </el-table>
          <!--分页器-->
          <div class="block" style="text-align: center; margin: 30px 0">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="pageSizes" :page-size="PageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainone from "@/components/Mainone";
import maintwo from "@/components/Maintwo";
// import { ElMessage } from "element-plus";
import axios from "axios";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "vlist",
  data() {
    return {
      mainonebread: [
        {
          name: "靓号",
        },
        {
          name: "订单",
        },
        {
          name: "订单列表",
          path: "/list",
        },
      ], // 当前页-【面包屑导航】
      maintwoiconshow: false, // 筛选-【高级筛选按钮】
      maintwoforminfo: {
        myinputoneisshow: true, //筛选查询-左侧字+input
        myinputone: {
          divinterval: "0", //框右侧距离
          titlewidth: "74px", //标题宽度
          inputwidth: "200px", //input宽度
          inputinterval: "20px", //input右侧距离
          input: [
            {
              ismust: false, //是否必填，true表示必填
              title: "订单编号：",
              placeholder: "输入搜索",
              name: "trade_no",
              content: "",
            },
          ],
        },
        // myinputonefullisshow: true, //筛选查询-自动建议下拉框
        // myinputonefull: {
        //   divinterval: "0", //框右侧距离
        //   titlewidth: "74px", //标题宽度
        //   inputwidth: "200px", //input宽度
        //   inputinterval: "20px", //input右侧距离
        //   input: [
        //     {
        //       ismust: false, //是否必填，true表示必填
        //       title: "设备编号：",
        //       placeholder: "请输入设备编号", //此name为键值
        //       name: "client",
        //       content: "",
        //       id: "", //此id为给后台的id值
        //       url: "/manage/Equipment/searchClient",
        //       posttype: 'get',
        //       inputval: { client: "" }, //这里记录需要传的参数
        //     },
        //   ],
        // },
        selectleftisshow: true, //筛选查询-左侧字+select单框||select时间
        selectleft: {
          width: "none",
          divinterval: "", //框右侧距离
          selectwidth: "200px",
          selectinterval: "20px", //select右侧距离
          select: [
            {
              selectisshow: true,
              ismust: false,
              title: "状态：",
              placeholder: "全部",
              name: "pay_status",
              value: "",
              options: [{
				  value:'',
				  label:'全部'
			  },{
				  value:0,
				  label:'待支付'
			  },{
				  value:2,
				  label:'已发货'
			  },
			  {
				  value:1,
				  label:'已支付'
			  },
			  {
				  value:-2,
				  label:'已退款'
			  },
			  {
				  value:-3,
				  label:'申请退款'
			  }
			  ],
            },
            {
              selecttimeduanisshow: false, //该字段表示时间段模块状态：且已写死（字段名不可修改!）
              ismust: false,
              title: "",
              placeholder: {
                placeholderstart: "请选择开始时间",
                placeholderend: "请选择结束时间",
              },
              name: "timerange",
              value: {
                valuestart: ref(""),
                valueend: ref(""),
                endstatus: true,
              },
              rangeseparator: "至", //分隔符
              divinterval: "3%", //上方字中-框右侧距离
              selectwidth: "100%", //上方字中-总框长度
              selectmaxwidth: "97%", //时间段-最大宽度
              marginright: "0", //与右侧距离
            },
          ],
        },
      }, // 筛选-控件信息
      mainthreeisshowbut: false, // 数据列表中-【添加按钮】
      mainthreeisshowset: false, // 数据列表中-复选框
      mainthreeisshowope: true, // 数据列表中-操作-根据页面显示模块（整页）
      mainthreetableope: 1, // 数据列表中-操作-根据页面显示控件（整页）
      mainthreehandle: 250, // 数据列表中-操作-宽度
      mainthreetabletitle: [
        {
          fixedstatu: "left",
          prop: "id",
          label: "订单id",
          width: "120",
        },
        {
          fixedstatu: false,
          prop: "trade_no",
          label: "订单编号",
          width: "200",
        },
		{
		  fixedstatu: false,
		  prop: "uid",
		  label: "用户id",
		  width: "100",
		},
        {
          fixedstatu: false,
          prop: "telephone",
          label: "靓号",
          width: "150",
        },
        {
          fixedstatu: false,
          prop: "pay_money",
          label: "支付金额",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "receiveName",
          label: "收件人",
          width: "",
        },
        {
          fixedstatu: false,
          prop: "receivePhone",
          label: "收件人手机号",
          width: "120",
        },
		{
		  fixedstatu: false,
		  prop: "address",
		  label: "收货地址",
		  width: "100",
		},
		{
		  fixedstatu: false,
		  prop: "pay_status_name",
		  label: "支付状态",
		  width: "80",
		},
		{
		  fixedstatu: false,
		  prop: "mobileinfostring",
		  label: "手机发货信息",
		  width: "80",
		},
      ], // 数据列表中-数据表头
      mainthreestatus: true, // 数据列表中-属于search按钮表示状态
      mainthreetableData: [], // 数据列表中-具体数据
      totalCount: 1, //数据列表中-数据总条数
      currentpage: 1, //数据列表中-数据当前页数
      lastpage: 1, //数据列表中-数据总页数（默认以20条一页）
      loading: false, //初始化-loading加载动效，默认是false关闭

      //table表单相关数据
      seniorform: {},
      currentPage: 1,
      pageSizes: [20, 50, 100],
      PageSize: 20,
      multipleSelection: [],
      thispagename: "", //通过路由获取当前页面的path值
      searchwidth: "580px",
      tancontent: {
        title: "弹框标题",
        content: {},
      }, //弹框

      mypowerlimits: JSON.parse(localStorage.getItem("powerlimits")), //获取权限
      mytoken: localStorage.getItem("token"), //获取存储的token
      pagesdatas: [], //存储最近一次的筛选条件，供分页使用
    };
  },
  mounted() {
    //获取当前页面路由path值
    this.thispagename = this.$route.path;
  },
  methods: {
    //table表单-点击单位格-带参跳转等
    handleOneData(row, column, cell, event) {
      // console.log(row);  //获取当前行数据
      // console.log(column); //获取当前单位格信息 ，通过column.property可获取当前单元的title得name值
      // console.log(event); //获取当前指针事件
      console.log(event.path[1].id); //获取当前指针所指向的单元格id值，配合标签的id使用
    },
    //table表-操作中-删除按钮（函数）
    deleteRow(index, rows) {
      //在table表中删除当前行信息，而rows.splice(index, 1)仅属于前端删除，可配合后具体操作
      rows.splice(index, 1);
    },
    //table表中-当选项发生变化是触发该函数
    handleSelectionChange(val) {
      console.log("this is:" + val);
      this.multipleSelection = val;
    },
    //分页器中-当选择条数时触发该函数
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.PageSize = val;
      this.currentPage = 1;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/manage/gmphone/list",
          {
            client: this.pagesdatas.client,
            companyid: this.pagesdatas.companyid,
            timerange: this.pagesdatas.timerange,
            page: 1,
            page_size: val,
          },
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //分页器中-当选择分页时触发该函数
    handleCurrentChange(val) {
      console.log(`当前页: 第${val}页`);
      this.currentPage = val;

      //加载loading
      this.loading = true;
      //通过Ajax向后台获取数据
      axios
        .post(
          "/manage/gmphone/list",
          {
            pay_status: this.pagesdatas.pay_status,
            trade_no: this.pagesdatas.trade_no,
            timerange: this.pagesdatas.timerange,
            page: val,
            page_size: this.PageSize,
          },
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //table表中-表格带状态-可扩展
    tableRowClassName({ rowIndex }) {
      //隔行显示
      if ((rowIndex + 1) % 2 === 0) {
        return "warning-row";
      }
      return "";
    },
    //点击按钮-弹出弹框并赋值弹窗基本配置
    addTanClick(taninfo) {
      console.log(taninfo);
      this.tancontent.content = taninfo.content;
      this.tancontent.title = taninfo.title;
    },
    //点击进行路由页面跳转以及携带参数
    routeraddrClick(routeraddr) {
      this.$router.push(routeraddr).catch((error) => error);
    },
    //导出数据
    exportDatas() {
      axios
        .post(
          "/manage/gmphone/list",
          {
            client: this.pagesdatas.client,
            companyid: this.pagesdatas.companyid,
            timerange: this.pagesdatas.timerange,
            export: 1,
          },
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
            console.log(response.data.message)
        //   ElMessage({ type: "success", message: response.data.message });
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    //获取当前windows页面信息-实时修改【弹窗】的宽度
    getwindowinfo() {
      this.sreenheight = document.documentElement.clientHeight;
      this.sreenwidth = document.documentElement.clientWidth;
      if (this.sreenwidth < 600) {
        this.searchwidth = this.sreenwidth * 0.8 + "px";
      } else {
        this.searchwidth = "580px";
      }
    },
	fahuo(row,index){
		var that = this
		this.$prompt('请输入快递单号格式:快递公司-快递单号', '发货信息', {
		          confirmButtonText: '确定',
		          cancelButtonText: '取消',
				  inputValidator:function(value){
					if(!value) return '快递信息必填'; 
					if(!value.includes('-')) return '快递信息有错误;格式:快递公司-快递单号';
				  },
				  inputErrorMessage:'快递信息有误',
		        }).then(({ value }) => {
		          axios
		            .post(
		              "/manage/gmphone/fahuo",
		              {
		                id: row.id,
		                mobileinfo: value
		              },
		              {
		                headers: {
		                  Auth: localStorage.getItem("token"),
		                },
		              }
		            )
		            .then((response) => {
						console.log('11111111',response.data.result)
						row.mobileinfo =  response.data.result
						row.mobileinfostring = response.data.result.deliveryCompany+'--'+response.data.result.deliveryNo
						
					  
		            })
		            .catch(function (error) {
		              console.log(error);
		            });
		        }).catch(() => {
		          this.$message({
		            type: 'info',
		            message: '取消输入'
		          });       
		        });
	},
	cancelorder(row){
		this.$confirm('确认取消订单吗,取消后靓号也会被取消', '取消订单', {
		          confirmButtonText: '确定',
				  cancelButtonText: '取消'}).then(()=>{
					  axios
					    .post(
					      "/manage/gmphone/cancelorder",
					      {
					        id: row.id
					      },
					      {
					        headers: {
					          Auth: localStorage.getItem("token"),
					        },
					      }
					    )
					    .then((response) => {
					      if(response.data.result === true){
							  row.pay_status = -2
							  row.pay_status_name =  '已退款'
						  }
					    })
					    .catch(function (error) {
					      console.log(error);
					    });
				  }).catch(() => {       
				
					});
			
	},
	refundorder(row){
		this.$confirm('确认退款吗,退款后靓号也会被取消', '取消订单', {
		          confirmButtonText: '确定',
				  cancelButtonText: '取消'}).then(()=>{
					  axios
					    .post(
					      "/manage/gmphone/refundorder",
					      {
					        id: row.id
					      },
					      {
					        headers: {
					          Auth: localStorage.getItem("token"),
					        },
					      }
					    )
					    .then((response) => {
					      if(response.data.result === true){
					      							  row.pay_status = -2
					      							  row.pay_status_name =  '已退款'
					      }
					    })
					    .catch(function (error) {
					      console.log(error);
					    });
				  }).catch(() => {       
				
				});
	},
    //提交等相关event事件函数
    //获取Container-Pagedata-Maintwo-搜索-返回的数据
    getSeachinfoEvent(data) {
    //   console.log("点击查询获取的data数组：" + JSON.stringify(data));
      this.pagesdatas = data; //将筛选条件赋值给本页的pagesdatas存储，供分页使用
      //加载loading
      this.loading = true;
      //判断-时间段状态
      var gettimerange = null;
      if (data.timerange.start == "") {
        gettimerange = "";
      } else {
        if (data.timerange.end == "") {
          gettimerange =
            data.timerange.start + " ~ " + (data.timerange.start + 86400 - 1);
        } else {
          gettimerange =
            data.timerange.start + " ~ " + (data.timerange.end + 86400 - 1);
        }
      }
      this.pagesdatas.timerange = gettimerange;

      axios
        .post(
          "/manage/gmphone/list",
          {
            trade_no: data.trade_no,
            pay_status: data.pay_status,
            timerange: gettimerange,
            page: 1,
            page_size: this.PageSize,
          },
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    //数据渲染
    myaxiosfunc(successdata) {
      var newdata = successdata.data;
      // console.log('当前页面的data:' + JSON.stringify(newdata))
      for (var i = 0; i < newdata.length; i++) {
		  if(newdata[i].mobileinfo){
			  console.log(newdata[i].id,newdata[i].mobileinfo)
			  newdata[i].mobileinfostring = newdata[i].mobileinfo.deliveryCompany+'--'+newdata[i].mobileinfo.deliveryNo
		  }else{
			   newdata[i].mobileinfostring = ''
		  }
		  newdata[i].pay_money = (newdata[i].pay_money/100).toFixed(2)
        // // 获取设备
        // newdata[i].client = newdata[i].ShareClient.client;
        // // 获取公司
        // newdata[i].company = newdata[i].Company ? newdata[i].Company.name : '';

        // // 时间
        // let timerangedata = newdata[i].timerange.split('-');
        // console.log(timerangedata)
        // newdata[i].time = timerangedata[0]
      }
      this.mainthreetableData = newdata;
      this.totalCount = successdata.total;
      this.currentpage = successdata.current_page; //当前页面页数
      this.lastpage = successdata.last_page;
      this.loading = false;
    },
    // 获取-公司-下拉信息
    getCompany(configinfo) {
      //获取api-加载类型信息（根据API返回的json数据结构不同，函数方法不同！）
      var alldatas = Array();
      var num = 0;
      alldatas[num] = { value: num, label: "全部" };
      for (var key in configinfo) {
        num = num + 1;
        alldatas[num] = {
          value: key,
          label: configinfo[key],
        };
      }
      //这里的select[0],是写死的，后续可优化！注意写死的状况下，select的位置不可移动
      this.maintwoforminfo.selectleft.select[0].options = alldatas;
    },
  },
  created() {
    if (localStorage.getItem("token") === null || !localStorage.getItem("token")) {
      this.isshow = false;
      this.$router.push("/").catch((error) => error);
    } else {
      window.addEventListener("resize", this.getwindowinfo); // 注册监听器-实时检测浏览器页面宽高
      this.getwindowinfo();

      //get请求配置参数-公司下拉
      // axios
      //   .get("/manage/gmphone/list", {
      //     headers: {
      //       Auth: localStorage.getItem("token"),
      //     },
      //     params: {},
      //   })
      //   .then((response) => {
      //     this.getCompany(response.data.result.companyid);
      //   })
      //   .catch(function (error) {
      //     console.log(error);
      //   });

      //加载loading
      this.loading = true; //通过Ajax向后台获取数据
      //通过Ajax向后台获取数据
      axios
        .post(
          "/manage/gmphone/list",
          {},
          {
            headers: {
              Auth: localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.myaxiosfunc(response.data.result);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  components: {
    mainone,
    maintwo,
  },
});
</script>
<style scoped>
/*操作中-按钮样式*/
.el-button--mini {
  min-height: 28px;
  padding: 0 8px;
  font-size: 12px;
  border-radius: calc(var(--el-border-radius-base) - 1px);
}
</style>